

<nav class="navbar navbar-expand-lg navbar-dark ftco_navbar bg-transparent ftco-navbar-light" id="ftco-navbar">
  <div class="container d-flex justify-content-between">
      <img src="assets/images/logo.png" class="navbar-logo" alt="Logo">
      <button
class="navbar-toggler"
type="button"
(click)="toggleMenu()"
[attr.aria-expanded]="collapseMenu"
aria-controls="ftco-nav"
aria-label="Toggle navigation"

>
<span class="oi oi-menu"></span> <i class="bi bi-list icon-large-bold"></i>
</button>


<div class="collapse navbar-collapse justify-content-end" id="ftco-nav" [ngClass]="{ 'show': collapseMenu }">
  <ul class="navbar-nav">
    <li class="nav-item"><a routerLink="/" class="nav-link">Accueil</a></li>
    <li class="nav-item"><a routerLink="/jeux-concours" class="nav-link">Jeux concours</a></li>
    <li class="nav-item"><a routerLink="/mentions-legales" class="nav-link">Mentions Légales</a></li>
    <li class="nav-item"><a routerLink="/contact" class="nav-link">Contact</a></li>
    <li *ngIf="user" class="nav-item"><a routerLink="/profile" class="nav-link">Profile</a></li>
    <li *ngIf="!user" class="nav-item"><a routerLink="/login" class="nav-link">Connexion</a></li>
    <li *ngIf="user" class="nav-item"><a routerLink="/login" (click)="logout()" class="nav-link">Déconnexion</a></li>
   </ul>
</div>

  </div>
</nav>
  