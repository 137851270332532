import { Component, OnInit } from '@angular/core';
import { GameService } from '../services/game.service';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-liste-employes',
  templateUrl: './liste-employes.component.html',
  styleUrls: ['./liste-employes.component.css']
})
export class ListeEmployesComponent implements OnInit {
  employees: any[] = [];
  paginatedEmployees: any[] = [];
  page: number = 1;
  totalPages: number = 1;
  itemsPerPage: number = 10;
  collapse = true;
  searchName: string = '';
  noResults: boolean = false;
  errorMessage: string = '';
  user: any;
  userId: string | null = null;

  constructor(private gameService: GameService, private authService: AuthService) {}

  ngOnInit(): void {
    this.userId = this.authService.getUserId();
    if (this.userId) {
      this.fetchUserByid();
    } else {
      console.error('User ID is not available.');
    }
    this.fetchEmployees();
  }

  searchEmployees() {
    this.noResults = false; 
    this.errorMessage = ''; 
    if (this.searchName.trim()) {
      this.gameService.searchEmployeesByName(this.searchName).subscribe(
        (data) => {
          if (data.length === 0) {
            this.noResults = true; 
            this.errorMessage = 'Aucun employé trouvé correspondant aux critères de recherche.';
            setTimeout(() => this.noResults = false, 3000);

            this.fetchEmployees(); 

          } else {
            this.employees = data;
            this.totalPages = Math.ceil(this.employees.length / this.itemsPerPage);
            this.loadPage(1); 
          }
        },
        (error) => {
          console.error('Error fetching employees:', error);
          this.errorMessage = 'An error occurred while searching for employees.';
        }
      );
    } else {
      this.fetchEmployees(); 
    }
  }
  resetSearch() {
    this.searchName = '';
    this.fetchEmployees(); 
  }

  fetchUserByid() {
    if (this.userId) {
      this.gameService.getUserById(this.userId).subscribe(
        (data) => {
          this.user = data;
        },
        (error) => {
          console.error('Error fetching user data', error);
        }
      );
    }
  }

  togglesidebar() {
    this.collapse = !this.collapse;
  }

  fetchEmployees(): void {
    this.gameService.getEmployees().subscribe(
      (data) => {
        this.employees = data;
        this.totalPages = Math.ceil(this.employees.length / this.itemsPerPage);
        this.loadPage(this.page);
      },
      (error) => {
        console.error('Error fetching employees:', error);
      }
    );
  }

  loadPage(page: number): void {
    this.page = page;
    const start = (this.page - 1) * this.itemsPerPage;
    const end = start + this.itemsPerPage;
    this.paginatedEmployees = this.employees.slice(start, end);
  }

  changePage(page: number): void {
    if (page < 1 || page > this.totalPages) return;
    this.loadPage(page);
  }

  confirmDelete(id: string): void {
    if (confirm('Êtes-vous sûr de vouloir supprimer cet employé ?')) {
      this.deleteEmployee(id);
    }
  }

  deleteEmployee(id: string): void {
    this.gameService.deleteEmployee(id).subscribe(
      () => {
        this.fetchEmployees();
      },
      (error) => {
        console.error('Error deleting employee:', error);
      }
    );
  }

  logout(): void {
    this.authService.logout();
    window.location.href = '/login';
  }
}
