  
   
   <head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>CGU - Thé Tip Top</title>
    <meta name="robots" content="noindex, nofollow">
</head>
<app-navbar></app-navbar>
    <div class="hero-wrap" style="background-image: url('assets/images/bg_2.jpg');" data-stellar-background-ratio="0.5">
        <div class="overlay"></div>
        <div class="overlay-2"></div>
        <div class="container">
          <div class="row no-gutters slider-text justify-content-center align-items-center">
            <div class="col-lg-8 col-md-6 ftco-animate d-flex align-items-end">
                <div class="text text-center w-100">
                  <h1 class="mb-4"> C.G.U</h1>
              </div>
            </div>
          </div>
        </div>
        <div class="mouse">
                  <a href="#" class="mouse-icon">
                      <div class="mouse-wheel"><span class="ion-ios-arrow-round-down"></span></div>
                  </a>
              </div>
      </div>
      <section class="ftco-section ftco-no-pb" style="margin-top: -200px;">
    	<div class="container">
	    	<div class="row">
					<div class="col-md-12">
                        <div class="pdf-viewer"> <embed src="assets/files/VosMentionsLegales.pdf" type="application/pdf" width="100%" height="1000px" />  </div>
					</div>
	    	</div>
	    </div>
    </section>

    <app-footer></app-footer>
