import { Component, OnInit } from '@angular/core';
import { GameService } from '../services/game.service';
import { AuthService } from '../services/auth.service';
@Component({
  selector: 'app-ticket-table',
  templateUrl: './ticket-table.component.html',
  styleUrls: ['./ticket-table.component.css']
})
export class TicketTableComponent implements OnInit {
  tickets: any[] = [];
  page: number = 1;
  limit: number = 10;
  totalPages: number = 1;
  user: any;
  userId: string | null = null; 
  searchCode: string = '';
  errorMessage: string = '';
  showErrorMessage: boolean = false;


  constructor(private gameService: GameService,    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.userId = this.authService.getUserId(); 
    if (this.userId) {
      this.fetchUserByid();
    } else {
      console.error('User ID is not available.');
    }
    this.loadTickets();
  }

  fetchUserByid() {
    if (this.userId) {
      this.gameService.getUserById(this.userId).subscribe(
        (data) => {
          this.user = data;
        },
        (error) => {
          console.error('Error fetching user data', error);
        }
      );
    }
  }
  loadTickets(): void {
    this.gameService.getTickets(this.page, this.limit).subscribe({
      next: (response) => {
        this.tickets = response.tickets;
        this.totalPages = response.totalPages;
      },
      error: (err) => {
        console.error('Error fetching tickets:', err);
      }
    });
  }
  resetSearch() {
    this.searchCode = '';
    this.loadTickets(); 
  }
  updateRemis(ticketId: string, currentRemis: boolean) {
    const newRemis = !currentRemis;
    this.gameService.updateRemis(ticketId, newRemis).subscribe(
      (updatedTicket) => {
        const index = this.tickets.findIndex(ticket => ticket._id === ticketId);
        if (index !== -1) {
          this.tickets[index].remis = newRemis;
        }
      },
      (error) => {
        console.error('Error updating remis field:', error);
      }
    );
  }

  changePage(page: number): void {
    this.page = page;
    this.loadTickets();
  }

  logout(): void {
    this.authService.logout();
    window.location.href = '/login'; 
  }


searchTicket() {
  if (this.searchCode) {
    this.gameService.searchTicketByCode(this.searchCode).subscribe(
      (ticket) => {
         this.tickets = [ticket]; 
  
        this.searchCode = '';
      },
      (error) => {
        console.error('Error searching ticket:', error);
        this.errorMessage = 'Code introuvable.';
        this.showErrorMessage = true;
        setTimeout(() => this.showErrorMessage = false, 3000);
      }
    );
  } else {
    console.warn('Please enter a ticket code to search.');
  }
}
}
