import { Component, OnInit } from '@angular/core';
import { GameService } from '../services/game.service';
import { AuthService } from '../services/auth.service'; 
import { Chart,ChartConfiguration  } from 'chart.js/auto'; 

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.css']
})
export class AdminDashboardComponent implements OnInit {
  ticketsValidatedCount: any ;
  remisTicketsCount:any;
  userId: string | null = null; 
  ticketCounts: { [key: string]: number } = {};
  clients: any[] = [];
  employees: any[] = [];
  latestTickets: any[] = [];
  genderChart: any;
  ageCategoryData: any = {};
  user: any;

  constructor(private gameService: GameService,    private authService: AuthService 
  ) {}

  ngOnInit(): void {
    this.userId = this.authService.getUserId(); 
    if (this.userId) {
      this.fetchUserByid();
    } else {
      console.error('User ID is not available.');
    }
    this.fetchTicketsValidated();
    this.fetchRemisTicketsCount();
    this.fetchTicketCounts();
    this.fetchEmployees();
    this.fetchClients();
    this.fetchLatestTickets();
    this.fetchGenderData();
    this.fetchAgeCategoryData();

  }


  fetchUserByid() {
    if (this.userId) {
      this.gameService.getUserById(this.userId).subscribe(
        (data) => {
          this.user = data;
        },
        (error) => {
          console.error('Error fetching user data', error);
        }
      );
    }
  }

  
  fetchAgeCategoryData(): void {
    this.gameService.getAgeCategoryTicketCounts().subscribe(
      (data) => {
        this.ageCategoryData = data;
        console.log('Age Category Data:', this.ageCategoryData); 
        this.renderLineChart();
      },
      (error) => {
        console.error('Error fetching age category data:', error);
      }
    );
  }

  renderLineChart(): void {
    const ctx = document.getElementById('ageCategoryChart') as HTMLCanvasElement;

    if (ctx) {
      new Chart(ctx, {
        type: 'line',
        data: {
          labels: ['0-18', '18-40', '40-55', '55+'],
          datasets: [{
            label: 'Tickets remis par catégorie d\'âge',
                        data: [
              this.ageCategoryData['0-18'],
              this.ageCategoryData['18-40'],
              this.ageCategoryData['40-55'],
              this.ageCategoryData['55+']
            ],
            borderColor: 'rgba(75, 192, 192, 1)',
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            fill: true,
            tension: 0.1
          }]
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: true,
              position: 'top'
            }
          },
          scales: {
            x: {
              title: {
                display: true,
                text: 'catégories d\'âge'
              }
            },
            y: {
              title: {
                display: true,
                text: 'Nombre de tickets'
              },
              beginAtZero: true
            }
          }
        }
      });
    } else {
      console.error('Could not find the canvas element.');
    }
  }
  fetchGenderData(): void {
    this.gameService.getUsedTicketsCountByGender().subscribe(
      (data) => {
        this.renderGenderChart(data);
      },
      (error) => {
        console.error('Error fetching gender data:', error);
      }
    );
  }

  renderGenderChart(data: any): void {
    const ctx = document.getElementById('genderChart') as HTMLCanvasElement;

    this.genderChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: ['Hommes', 'Femmes'],
        datasets: [
          {
            label: 'Tickets validés',
            data: [data.maleCount, data.femaleCount],
            backgroundColor: 'rgba(54, 162, 235, 0.2)',
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 1,
          },
          {
            label: 'Tickets Remis',
            data: [data.maleCountRemis, data.femaleCountRemis],
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            borderColor: 'rgba(255, 99, 132, 1)',
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    });
  }
  fetchLatestTickets(): void {
    this.gameService.getLatestTickets().subscribe(
      (data) => {
        this.latestTickets = data;
        console.log('Latest Tickets:', this.latestTickets); 
      },
      (error) => {
        console.error('Error fetching latest tickets:', error);
      }
    );
  }
  fetchEmployees(): void {
    this.gameService.getLimitedEmployees().subscribe(
      (data) => {
        this.employees = data;
        console.log('Employees:', this.employees);
      },
      (error) => {
        console.error('Error fetching employees:', error);
      }
    );
  }

  fetchClients(): void {
    this.gameService.getLimitedClients().subscribe(
      (data) => {
        this.clients = data;
        console.log('Clients:', this.clients); 
      },
      (error) => {
        console.error('Error fetching clients:', error);
      }
    );
  }
  fetchTicketCounts(): void {
    this.gameService.getTicketCountsByValue().subscribe(
      (data) => {
        this.ticketCounts = data;
        console.log('Fetched ticket counts:', this.ticketCounts);
      },
      (error) => {
        console.error('Error fetching ticket counts:', error);
      }
    );
  }
  fetchTicketsValidated(): void {
    this.gameService.getTicketsValidated().subscribe(
      (data) => {
        this.ticketsValidatedCount = data.usedTicketsCount;
      },
      (error) => {
        console.error('Error fetching validated tickets count:', error);
      }
    );
  }

  fetchRemisTicketsCount(): void {
    this.gameService.getRemisTicketsCount().subscribe(
      (data) => {
        this.remisTicketsCount = data.remisTicketsCount;
      },
      (error) => {
        console.error('Error fetching remis tickets count:', error);
      }
    );
  }
  logout(): void {
    this.authService.logout(); 
    window.location.href = '/login'; 
  }
}
