<!DOCTYPE html>
<html lang="en">
  <head>
	<!-- Google Tag Manager -->
	<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
	new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
	j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
	'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
	})(window,document,'script','dataLayer','GTM-5HPSX93G');</script>
	<!-- End Google Tag Manager -->
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Thé Tip Top - Jeu-Concours Spécial 10ème Boutique</title>
    <meta name="description" content="Participez à notre Jeu-Concours Spécial pour célébrer l'ouverture de notre 10ème boutique ! Gagnez de superbes cadeaux en jouant avec nous. Rejoignez-nous pour fêter cette grande étape !">
   <link href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" rel="stylesheet">

  

    
  </head><app-navbar></app-navbar>

  <body>
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5HPSX93G"
	height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
  <!-- <ngx-spinner
  bdColor="rgba(0, 0, 0, 1)"
  template="<img src='https://media.giphy.com/media/o8igknyuKs6aY/giphy.gif' />"
>
</ngx-spinner> --> 
	<ngx-spinner bdColor = "rgba(212, 202, 104, 0.99)" size = "medium" color = "#fff"  [fullScreen] = "true"><div class="container">
		<div class="col-md-12">
			<div class="content fullscreen">
				<div class="circle"></div>
				<div class="circle"></div>
				<div class="circle"></div>
				<div class="circle"></div>
			</div>
		</div>
	</div>
	</ngx-spinner>    
    <div class="hero-wrap" style="background-image: url('assets/images/bg_4.jpg');" data-stellar-background-ratio="0.5">
      <div class="overlay"></div>
      <div class="overlay-2"></div>
      <div class="container">
        <div class="row no-gutters slider-text justify-content-center align-items-center">
          <div class="col-lg-8 col-md-6 ftco-animate d-flex align-items-end">
          	<div class="text text-center w-100">
	            <h1 class="mb-4">Gagnez gros avec notre jeu-concours spécial 10ème boutique !</h1>
	            <p><a routerLink="/jeux-concours" class="btn btn-primary  py-3 px-4">Le jeu est Simple</a></p>

            </div>
          </div>
        </div>
      </div>
      
    </div>


   
    <section class="ftco-section goto-here">
    	<div class="container">
    		<div class="row justify-content-center">
          <div class="col-md-12 heading-section text-center ftco-animate mb-5">
          	<span class="subheading">--- CE QUE NOUS OFFRONS ---
			</span>
            <h2 class="mb-2">Cadeaux Exclusive Pour Vous</h2>
          </div>
        </div>
        <div class="row">
        	<div class="col-md-4">
        		<div class="property-wrap ftco-animate">
        			<div class="img d-flex align-items-center justify-content-center" style="background-image: url(assets/images/infuseur.jpg);">
        				<a routerLink="jeux-concours"  class="icon d-flex align-items-center justify-content-center btn-custom">
        					<i class="bi bi-joystick"></i>
        				</a>
        			
        			</div>
        			<div class="text">
        				<p class="price mb-3"><span class="orig-price">Cadeau N°1</span></p>
        				<h3 class="mb-0"><a routerLink="jeux-concours">Infuseur à thé</a></h3>
        				<span class="location d-inline-block mb-3"><i class="ion-ios-pin mr-2"></i>Un infuseur à thé permet d'infuser le thé en vrac.</span>
        				
        			</div>
        		</div>
        	</div>
        	<div class="col-md-4">
        		<div class="property-wrap ftco-animate">
        			<div class="img d-flex align-items-center justify-content-center" style="background-image: url(assets/images/the-box.jpg);">
        				<a routerLink="jeux-concours" class="icon d-flex align-items-center justify-content-center btn-custom">
        					<i class="bi bi-joystick"></i>
        				</a>
        			
        			</div>
        			<div class="text">
        				<p class="price mb-3"><span class="orig-price">Cadeau N°2</span></p>
        				<h3 class="mb-0"><a routerLink="jeux-concours"> Une boite de thé détox ou d’infusion</a></h3>
        				<span class="location d-inline-block mb-3"><i class="ion-ios-pin mr-2"></i>Une boîte de thé détox pour purifier et revitaliser.</span>
        				
        			</div>
        		</div>
        	</div>
        	<div class="col-md-4">
        		<div class="property-wrap ftco-animate">
        			<div class="img d-flex align-items-center justify-content-center" style="background-image: url(assets/images/signature.jpg);">
        				<a routerLink="jeux-concours" class="icon d-flex align-items-center justify-content-center btn-custom">
        					<i class="bi bi-joystick"></i>
        				</a>
        				
        			</div>
        			<div class="text">
        				<p class="price mb-3"><span class="orig-price">Cadeau N°3</span></p>
        				<h3 class="mb-0"><a routerLink="jeux-concours">Une boite de de thé signature</a></h3>
        				<span class="location d-inline-block mb-3"><i class="ion-ios-pin mr-2"></i>Une boîte de thé offrant une expérience unique.






						</span>
        			
        			</div>
        		</div>
        	</div>

        	<div class="col-md-4">
        		<div class="property-wrap ftco-animate">
        			<div class="img d-flex align-items-center justify-content-center" style="background-image: url(assets/images/coffret1.jpg);">
        				<a routerLink="jeux-concours" class="icon d-flex align-items-center justify-content-center btn-custom">
        					<i class="bi bi-joystick"></i>
        				</a>
        				
        			</div>
        			<div class="text">
        				<p class="price mb-3"><span class="orig-price">Cadeau N°4</span></p>
        				<h3 class="mb-0"><a routerLink="jeux-concours">Un coffret découverte d'une valeur de 39 €</a></h3>
        				<span class="location d-inline-block mb-3"><i class="ion-ios-pin mr-2"></i>Un coffret offrant une sélection variée de thés</span>
        				
        			</div>
        		</div>
        	</div>
        	<div class="col-md-4">
        		<div class="property-wrap ftco-animate">
        			<div class="img d-flex align-items-center justify-content-center" style="background-image: url(assets/images/coffret2.jpg);">
        				<a routerLink="jeux-concours" class="icon d-flex align-items-center justify-content-center btn-custom">
        					<i class="bi bi-joystick"></i>
        				</a>
        			
        			</div>
        			<div class="text">
        				<p class="price mb-3"><span class="orig-price">Cadeau N°5</span></p>
        				<h3 class="mb-0"><a routerLink="jeux-concours">Un coffret découverte d'une valeur de 69 €</a></h3>
        				<span class="location d-inline-block mb-3"><i class="ion-ios-pin mr-2"></i>Un coffret offrant une sélection variée de thés</span>
        				
        			</div>
        		</div>
        	</div>
        	<div class="col-md-4">
        		<div class="property-wrap ftco-animate">
        			<div class="img d-flex align-items-center justify-content-center" style="background-image: url(assets/images/work-6.jpg);">
        				<a routerLink="jeux-concours" class="icon d-flex align-items-center justify-content-center btn-custom">
        					<i class="bi bi-joystick"></i>
        				</a>
        			
        			</div>
        			<div class="text">
        				<p class="price mb-3"><span class="orig-price">Le Grand Prix</span></p>
        				<h3 class="mb-0"><a routerLink="jeux-concours">1 an de thé d'une valeur de 360 €</a></h3>
        				<span class="location d-inline-block mb-3"><i class="ion-ios-pin mr-2"></i>un an de thé, d'une valeur de 360 euros.</span>
        				
        			</div>
        		</div>
        	</div>
        </div>
    	</div>
    </section>

    <section class="ftco-section ftco-fullwidth">
    	<div class="overlay"></div>
    	<div class="container">
    		<div class="row justify-content-center">
          <div class="col-md-12 heading-section text-center ftco-animate mb-5">
          	<span class="subheading">--- Services ---</span>
            <h2 class="mb-2">Comment ça marche?</h2>
          </div>
        </div>
    	</div>
    	<div class="container-fluid px-0">
    		<div class="row d-md-flex text-wrapper align-items-stretch">
					<div class="one-half mb-md-0 mb-4 img2 img d-flex align-self-stretch" style="background-image: url('assets/images/tea-time2.avif');"></div>
					<div class="one-half half-text d-flex justify-content-end align-items-center">
						<div class="text-inner pl-md-5">
							<div class="row d-flex">
			          <div class="col-md-12 d-flex align-self-stretch ftco-animate">
			            <div class="media block-6 services-wrap d-flex">
			            	<div class="icon d-flex justify-content-center align-items-center"><svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" fill="currentColor" class="bi bi-cash white-icon" viewBox="0 0 16 16">
								<path d="M8 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4"/>
								<path d="M0 4a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1zm3 0a2 2 0 0 1-2 2v4a2 2 0 0 1 2 2h10a2 2 0 0 1 2-2V6a2 2 0 0 1-2-2z"/>
							  </svg>
							  </div>
			              <div class="media-body pl-4"  style="margin-left: 20px;">
			                <h2>Etape N°1</h2>
			                <h5>Réalisez au moins 49€ d'achat dans une boutique ThéTipTop.</h5>
			              </div>
			            </div>      
			          </div>
			          <div class="col-md-12 d-flex align-self-stretch ftco-animate">
			            <div class="media block-6 services-wrap d-flex">
			            	<div class="icon d-flex justify-content-center align-items-center"><svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-person-add white-icon" viewBox="0 0 16 16">
								<path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0m-2-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0M8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4"/>
								<path d="M8.256 14a4.5 4.5 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10q.39 0 .74.025c.226-.341.496-.65.804-.918Q8.844 9.002 8 9c-5 0-6 3-6 4s1 1 1 1z"/>
							  </svg>
							  </div>
			              <div class="media-body pl-4" style="margin-left: 20px;">
			                <h2>Etape N°2</h2>
			                <h5>Inscrivez vous sur notre site jeux concours.</h5>
			              </div>
			            </div>      
			          </div>
			          <div class="col-md-12 d-flex align-self-stretch ftco-animate">
			            <div class="media block-6 services-wrap d-flex">
			            	<div class="icon d-flex justify-content-center align-items-center white-icon"><svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-laptop" viewBox="0 0 16 16">
								<path d="M13.5 3a.5.5 0 0 1 .5.5V11H2V3.5a.5.5 0 0 1 .5-.5zm-11-1A1.5 1.5 0 0 0 1 3.5V12h14V3.5A1.5 1.5 0 0 0 13.5 2zM0 12.5h16a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 12.5"/>
							  </svg></div>
			              <div class="media-body pl-4"  style="margin-left: 20px;">
			                <h2>Etape N°3</h2>
			                <h5>Connectez vous et participez en saisissant votre code de ticket.</h5>
			              </div>
			            </div>      
			          </div>
			          <div class="col-md-12 d-flex align-self-stretch ftco-animate">
			            <div class="media block-6 services-wrap d-flex">
			            	<div class="icon d-flex justify-content-center align-items-center"><svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-shop white-icon" viewBox="0 0 16 16">
								<path d="M2.97 1.35A1 1 0 0 1 3.73 1h8.54a1 1 0 0 1 .76.35l2.609 3.044A1.5 1.5 0 0 1 16 5.37v.255a2.375 2.375 0 0 1-4.25 1.458A2.37 2.37 0 0 1 9.875 8 2.37 2.37 0 0 1 8 7.083 2.37 2.37 0 0 1 6.125 8a2.37 2.37 0 0 1-1.875-.917A2.375 2.375 0 0 1 0 5.625V5.37a1.5 1.5 0 0 1 .361-.976zm1.78 4.275a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 1 0 2.75 0V5.37a.5.5 0 0 0-.12-.325L12.27 2H3.73L1.12 5.045A.5.5 0 0 0 1 5.37v.255a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0M1.5 8.5A.5.5 0 0 1 2 9v6h1v-5a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v5h6V9a.5.5 0 0 1 1 0v6h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1V9a.5.5 0 0 1 .5-.5M4 15h3v-5H4zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1zm3 0h-2v3h2z"/>
							  </svg></div>
			              <div class="media-body pl-4"  style="margin-left: 20px;">
			                <h2>Etape N°4</h2>
			                <h5>Réclamez votre cadeau en magasin ou en ligne.</h5>
			              </div>
			            </div>      
			          </div>
			        </div>
            </div>
					</div>
    		</div>
    	</div>
    </section>



    <section class="ftco-section">
    	<div class="container">
    		<div class="row justify-content-center">
          <div class="col-md-12 heading-section text-center ftco-animate mb-5">
          	<span class="subheading">--- Nos boutiques ---</span>
            <h2 class="mb-2">Trouvez des boutiques dans votre ville</h2>
          </div>
        </div>
        <div class="row">
        	<div class="col-md-4">
        		<div class="listing-wrap img img3 rounded d-flex align-items-end" style="background-image: url(assets/images/nice.jfif);">
        		
        			<div class="text">
        				<h3><a href="#">NICE</a></h3>
        				<a href="#" class="btn-link">1 rue Blacas,06000 Nice <span class="ion-ios-arrow-round-forward"></span></a>
        			</div>
        		</div>
        	</div>
        	<div class="col-md-4">
        		<div class="listing-wrap img img3 rounded d-flex align-items-end" style="background-image: url(assets/images/paris.jfif);">
        		
					
        			<div class="text">
        				<h3><a href="#">PARIS</a></h3>
        				<a href="#" class="btn-link">64 Rue Vieille du Temple,

							75003 Paris <span class="ion-ios-arrow-round-forward"></span></a>
        			</div>
        		</div>
        	</div>
        	<div class="col-md-4">
        		<div class="listing-wrap img img3 rounded d-flex align-items-end" style="background-image: url(assets/images/lyon.jfif);">
        		
        			<div class="text">
        				<h3><a href="#">LYON</a></h3>
        				<a href="#" class="btn-link">Place Bellecour, 69002 Lyon <span class="ion-ios-arrow-round-forward"></span></a>
        			</div>
        		</div>
        	</div>
        	<div class="col-md-4">
        		<div class="listing-wrap img img3 rounded d-flex align-items-end" style="background-image: url(assets/images/strasbourg.jfif);">
        	
        			<div class="text">
        				<h3><a href="#">STRASBOURG</a></h3>
        				<a href="#" class="btn-link">2 Place du Château, 67000 Strasbourg <span class="ion-ios-arrow-round-forward"></span></a>
        			</div>
        		</div>
        	</div>
        	<div class="col-md-4">
        		<div class="listing-wrap img img3 rounded d-flex align-items-end" style="background-image: url(assets/images/rennes.jfif);">
        		
        			<div class="text">
        				<h3><a href="#">RENNES</a></h3>
        				<a href="#" class="btn-link">Place du Parlement de Bretagne, 35000 Rennes <span class="ion-ios-arrow-round-forward"></span></a>
        			</div>
        		</div>
        	</div>
        	<div class="col-md-4">
        		<div class="listing-wrap img img3 rounded d-flex align-items-end" style="background-image: url(assets/images/lille.jfif);">
        		
        			<div class="text">
        				<h3><a href="#">Lille</a></h3>
        				<a href="#" class="btn-link">Avenue Mathias Delobel, 59800 Lille <span class="ion-ios-arrow-round-forward"></span></a>
        			</div>
        		</div>
        	</div>
        </div>
    	</div>
    </section>

	<section class="ftco-section testimony-section bg-light">
		<div class="container">
		  <div class="row justify-content-center mb-5">
			<div class="col-md-7 text-center heading-section ftco-animate">
				<span class="subheading">--- Le cadeau incontournable ---</span>
			  <h2 class="mb-3">Tirage au sort</h2>
			</div>
		  </div>
		  <div class="row ftco-animate">
			<div class="col-md-12">
			  <div class="carousel-testimony owl-carousel ftco-owl">
				<div class="item">
				  <div class="testimony-wrap py-4">
					<div class="text">
					  <h6 class="mb-4" style="text-align: center;">Je suis ravi de vous informer que le tirage au sort du jeu-concours n°1 s’effectuera automatiquement parmi tous les participants. </h6>
					<h6 style="text-align: center;">Ce tirage aura lieu le 1er avril. Deux jours plus tard, vous serez informés du résultat par e-mail.</h6>
					<br>
					<h6 style="text-align: center;">Si vous avez la chance de remporter ce concours, il vous suffira de vous rendre en magasin avec l’e-mail reçu pour récupérer votre cadeau.
						
						Bonne chance à tous !</h6><br>
					  <div class="d-flex align-items-center" style="text-align: center;">
						  <div class="user-img" style="background-image: url(assets/images/person_2.jpg)"></div>
						  <div class="pl-6" style="margin-left: 20px;">
							  <p class="name">Mr Eric Bourdon</p>
							  <span class="position">Gérant Thé Tip Top</span>
							</div>
						</div>
					</div>
				  </div>
				</div>
				
			  </div>
			</div>
		  </div>
		</div>
	  </section>
  


  </body><app-footer></app-footer>

</html>