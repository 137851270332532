import { Component,OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-mentions-legales',
  templateUrl: './mentions-legales.component.html',
  styleUrls: ['./mentions-legales.component.css']
})
export class MentionsLegalesComponent implements OnInit {
  constructor(private titleService: Title, private metaService: Meta) {}

  ngOnInit(): void {
    this.titleService.setTitle('Mentions Légales - Thé Tip Top');
    this.metaService.addTags([
      { name: 'description', content: 'Consultez nos mentions légales pour obtenir des informations sur les conditions d\'utilisation, les politiques de confidentialité et plus encore.' },
      { name: 'robots', content: 'index, follow' }
    ]);
  }
}
