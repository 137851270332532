import { Component, OnInit,HostListener } from '@angular/core';
import { GameService } from '../services/game.service';
import { AuthService } from '../services/auth.service';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit{
  collapseMenu = false;
  isDesktop = true;

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.isDesktop = window.innerWidth > 768;
  }
  userId: string | null = null;
  user: any;

  constructor(private gameService: GameService, private authService: AuthService) {}

  ngOnInit() {
    this.isDesktop = window.innerWidth > 768;

    this.userId = this.authService.getUserId(); 

    if (this.userId) {
      this.fetchUserByid();
    } else {
      console.error('User ID is not available.');
    }
  }
  fetchUserByid() {
    if (this.userId) {
      this.gameService.getUserById(this.userId).subscribe(
        (data) => {
          this.user = data;
        },
        (error) => {
          console.error('Error fetching user data', error);
        }
      );
    }
  }
  toggleMenu() {
    this.collapseMenu = !this.collapseMenu;
  }
  logout(): void {
    this.authService.logout(); 
    window.location.href = '/login'; 
  }
}
