<footer class="ftco-footer ftco-section">
  <div class="container">
    <div class="row mb-5">
      <div class="col-md-2 text-center text-md-left mb-4 mb-md-0">
        <div class="ftco-footer-widget">
          <img src="assets/images/logo.png" class="img-fluid" alt="Thé Tip Top Logo">
        </div>
      </div>
      <div class="col-md-3 mb-4 mb-md-0">
        <div class="ftco-footer-widget block-23">
          <h2 class="ftco-heading-2">Siège Social</h2>
          <ul class="list-unstyled">
            <li>
              6 rue Anatole Forge,<br>
              75017 Paris, France
            </li>
            <li>+33 321 795 955</li>
            <li><a href="mailto:contact@thetiptopjeux.fr">thetiptop-jeuxconcours@outlook.com</a></li>
          </ul>
        </div>
      </div>
      <div class="col-md-2 mb-4 mb-md-0">
        <div class="ftco-footer-widget block-23">
          <h2 class="ftco-heading-2">Liens rapides</h2>
          <ul class="list-unstyled">
            <li><a href="contact.html"><span><i class="bi bi-arrow-right"></i></span> Nos Boutiques</a></li>
            <li><a routerLink="/contact"><span><i class="bi bi-arrow-right"></i></span> Nous Contacter</a></li>
            <li><a routerLink="/cgu"><span><i class="bi bi-arrow-right"></i></span> CGU</a></li>
            <li><a routerLink="/mentions-legales"><span><i class="bi bi-arrow-right"></i></span> Mentions Légales</a></li>
          </ul>
        </div>
      </div>
      <div class="col-md-2 mb-4 mb-md-0">
        <div class="ftco-footer-widget block-23">
          <h2 class="ftco-heading-2">Heures d'ouverture</h2>
          <ul class="list-unstyled">
            <li>Lundi - Vendredi: 09h30 - 19:00</li>
            <li>Samedi: 09h30 - 13:00</li>
            <li>Dimanche: Fermé</li>
          </ul>
        </div>
      </div>
      <div class="col-md-3">
        <div class="ftco-footer-widget block-23">
          <h2 class="ftco-heading-2">Newsletter</h2>
          <p>Inscrivez-vous pour recevoir la newsletter ThéTipTop!</p>
          <form action="#" class="subscribe-form">
            <button type="button" class="btn btn-light text-dark">Newsletters</button>
          </form>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 text-center">
        <p>
          Copyright &copy; Thé Tip Top 2024
        </p>
      </div>
    </div>
  </div>
</footer>








