<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Profile - Thé Tip Top</title>
    <meta name="robots" content="noindex, nofollow">
</head>
<app-navbar></app-navbar>
<div class="page-content page-container" id="page-content">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-8 col-md-10" style="margin-top: -150px;">
                <div class="card user-card-full">
                    <div class="row no-gutters">
                        <div class="col-sm-5 bg-c-lite-green user-profile">
                            <div class="card-block text-center text-white">
                                <div class="m-b-25">
                                    <img src="../assets/images/useravatar.png" class="img-radius" alt="User-Profile-Image">
                                </div>
                                <h6 class="f-w-600">{{user.name}} {{user.lastName}}</h6>
                                <p>TipToper</p>
                                <div class="button-row">
                                    <button class="btn btn-custom" (click)="toggleEditMode()">
                                        <i class="mdi mdi-account-edit"></i> 
                                        {{ editMode ? 'Annuler' : 'Modifier Profil' }}
                                    </button>
                                    <button class="btn btn-custom" *ngIf="editMode" (click)="saveProfile()">
                                        <i class="mdi mdi-content-save"></i> 
                                        Sauvegarder
                                    </button>
                                <!--    <button class="btn btn-custom"><i class="mdi mdi-lock-reset"></i> Modifier Mot de Passe</button>
                              -->  </div>
                            </div>
                        </div>
                        <div class="col-sm-7">
                            <div class="card-block">
                                <h6 class="m-b-20 p-b-5 b-b-default f-w-600">Informations</h6>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <p class="m-b-10 f-w-600">Nom</p>
                                        <h6 class="text-muted f-w-400" *ngIf="!editMode">{{user.name}}</h6>
                                        <input *ngIf="editMode" type="text" [(ngModel)]="editableUser.name" class="form-control">
                                    </div>
                                    <div class="col-sm-6">
                                        <p class="m-b-10 f-w-600">Prénom</p>
                                        <h6 class="text-muted f-w-400" *ngIf="!editMode">{{user.lastName}}</h6>
                                        <input *ngIf="editMode" type="text" [(ngModel)]="editableUser.lastName" class="form-control">
                                    </div>
                                    <div class="col-sm-6">
                                        <p class="m-b-10 f-w-600">Email</p>
                                        <h6 class="text-muted f-w-400">{{user.email}}</h6>
                                    </div>
                                    <div class="col-sm-6">
                                        <p class="m-b-10 f-w-600">Age</p>
                                        <h6 class="text-muted f-w-400" *ngIf="!editMode">{{user.age}}</h6>
                                        <input *ngIf="editMode" type="number" [(ngModel)]="editableUser.age" class="form-control">
                                    </div>
                                </div>
                                <h6 class="m-b-20 m-t-40 p-b-5 b-b-default f-w-600">Récompenses</h6>
                                <div class="row">
                                    <div *ngFor="let ticket of userTickets" class="col-sm-6 reward-container">
                                        <div class="reward-image-container">
                                            <img [src]="getTicketImage(ticket.value)" alt="Ticket Image" class="reward-image">
                                            <div class="reward-text">
                                                <p class="reward-value">{{ ticket.value }}</p>
                                                <p class="reward-code">{{ ticket.code }}</p>
                                                <p class="reward-code">{{ ticket.useDate | date:'yyyy-MM-dd' }}</p>
                                               

                                            </div>
                                        </div>  <div class="reward-status" [ngClass]="{'text-success': ticket.remis, 'text-danger': !ticket.remis}" style="font-weight: bold;margin-bottom: 50px;">
                                            {{ ticket.remis ? 'Remis' : 'Non Remis' }}
                                          </div><br>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>
