<!DOCTYPE html>
<html lang="fr">
   <head>
      <!-- basic -->
      <meta charset="utf-8">
      <meta http-equiv="X-UA-Compatible" content="IE=edge">
      <!-- mobile metas -->
      <meta name="viewport" content="width=device-width, initial-scale=1">
      <meta name="viewport" content="initial-scale=1, maximum-scale=1">
      <!-- site metas -->
      <title>Thé tip top - Liste employés</title>
         <meta name="robots" content="noindex, nofollow">
      <!-- site icon -->
      
       
   </head>
   <body class="dashboard dashboard_1">
      <div class="full_container">
         <div class="notification error" *ngIf="noResults">{{ errorMessage }}</div>

         <div class="inner_container">
            <nav id="sidebar" >
               <div class="sidebar_blog_1">
                  <div class="sidebar-header">
                
                  </div>
                  <div class="sidebar_user_info">
                     <div class="icon_setting"></div>
                     <div class="user_profle_side">
                        <div class="user_info">
                           <h6>{{user.name}} {{user.lastName}} </h6>
                           <p><span class="online_animation"></span> En ligne</p>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="sidebar_blog_2">
                  <h4>General</h4>
                  <ul class="list-unstyled components">
                     <li class="active">
                        <a href="#dashboard" data-toggle="collapse" aria-expanded="false" routerLink="/admin-dashboard"><i class="fa fa-dashboard yellow_color"></i> <span>Tableau de bord</span></a>
                     
                     </li>
                     <li><a routerLink="/ticket-table"><i class="fa fa-clock-o orange_color"></i> <span>Tickets</span></a></li>
                    
                     <li><a routerLink="/liste-employes"><i class="fa fa-table purple_color2"></i> <span>Employés</span></a></li>
                     <li><a routerLink="/liste-clients"><i class="fa fa-table purple_color2"></i> <span>Clients</span></a></li>
                  </ul>
               </div>
            </nav>
             <div id="content">
               <div class="topbar">
                  <nav class="navbar navbar-expand-lg navbar-light">
                     <div class="full">
                        
                        <button style="background-color: black;" type="button" id="sidebarCollapse" class="sidebar_toggle" (click)="togglesidebar()"><i bold class="bi bi-list" style="font-size: 1.5rem; font-weight: bold;"></i></button>
                        <div class="right_topbar">
                           <div class="icon_info">
                              <ul class="user_profile_dd">
                                 <li>
                                    <span class="logout-pointer" (click)="logout()">Déconnexion</span>
                                 </li>
                              </ul>
                           </div>
                        </div>
                     </div>
                  </nav>
               </div>
                <div class="midde_cont">
                <div class="container-fluid">
                    <div class="row column_title">
                       <div class="col-md-12">
                          <div class="page_title">
                             <h2>Tables</h2>
                             <button routerLink="/add-employe" class="btn btn-outline-secondary search-button" type="button">Ajouter un employé</button>

                          </div>
                       </div>
                    </div>
                    <!-- row -->
                    <div class="row"><div class="container-fluid">
                     <div class="container-fluid">
                       <div class="row">
                         <div class="col-md-12">
                           <div class="input-group mb-3 search-bar">
                             <input type="text" [(ngModel)]="searchName" class="form-control search-input" placeholder="Entrez le nom ou prénom de l'employé">
                             <div class="input-group-append">
                               <button (click)="searchEmployees()" class="btn btn-outline-secondary search-button" type="button">Rechercher</button>
                             
                             </div><button (click)="resetSearch()" class="btn btn-outline-secondary search-button" type="button">  <i style="color: black;" class="bi bi-x-circle-fill "></i>    </button>
                              
                           </div>
                         </div>
                       </div>
                     </div>
                     </div>
                    
                        <div class="col-md-12">
                            <div class="white_shd full margin_bottom_30">
                               <div class="full graph_head">
                                  <div class="heading1 margin_0">
                                     <h2>Liste des employés</h2>
                                  </div>
                               </div>
                               <div class="table_section padding_infor_info">
                                 <div class="table-responsive-sm">
                                   <table class="table">
                                     <thead>
                                       <tr>
                                         <th>Nom</th>
                                         <th>Prénom</th>
                                         <th>Email</th>
                                         <th>Sexe</th>
                                         <th>Age</th>
                                         <th>Actions</th>
                                       </tr>
                                     </thead>
                                     <tbody>
                                       <tr *ngFor="let employe of paginatedEmployees">
                                         <td>{{ employe.name }}</td>
                                         <td>{{ employe.lastName }}</td>
                                         <td>{{ employe.email }}</td>
                                         <td>{{ employe.sexe }}</td>
                                         <td>{{ employe.age }}</td>
                                         <td>
                                           <i (click)="confirmDelete(employe._id)" class="bi bi-trash delete-icon"></i>
                                         </td>
                                       </tr>
                                     </tbody>
                                   </table>
                                 </div>
                                 
                                 <div class="row">
                                    <div class="col-md-12">
                                      <nav aria-label="Page navigation">
                                        <ul class="pagination">
                                          <li class="page-item" [class.disabled]="page === 1">
                                            <a class="page-link" (click)="changePage(page - 1)" aria-label="Previous">
                                              <span aria-hidden="true">&laquo;</span>
                                            </a>
                                          </li>
                                          <li *ngFor="let p of [].constructor(totalPages); let i = index" class="page-item" [class.active]="page === i + 1">
                                            <a class="page-link" (click)="changePage(i + 1)">{{ i + 1 }}</a>
                                          </li>
                                          <li class="page-item" [class.disabled]="page === totalPages">
                                            <a class="page-link" (click)="changePage(page + 1)" aria-label="Next">
                                              <span aria-hidden="true">&raquo;</span>
                                            </a>
                                          </li>
                                        </ul>
                                      </nav>
                                    </div>
                                 </div>
                               </div>
                            </div>
                         </div>
                      
                    </div>
                 </div>
                  <div class="container-fluid">
                     <div class="footer">
                        <p>Copyright © 2024 Conçu par Hedi Ben Khouja. Tous droits réservés.. All rights reserved.</p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </body>
</html>
