import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

import { Title } from '@angular/platform-browser';
import { Meta } from '@angular/platform-browser';
@Component({
  selector: 'app-accueil',
  templateUrl: './accueil.component.html',
  styleUrls: ['./accueil.component.css']
})
export class AccueilComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService,private titleService: Title, private metaService: Meta) {}
  ngOnInit(): void {
    this.titleService.setTitle('Thé Tip Top - Jeu-Concours Spécial 10ème Boutique');
    this.metaService.addTags(
    [
      { name: 'description',  content:
        'Participez à notre Jeu-Concours Spécial pour célébrer l\'ouverture de notre 10ème boutique ! Gagnez de superbes cadeaux en jouant avec nous. Rejoignez-nous pour fêter cette grande étape !',
    },
      { name: 'robots', content: 'index, follow' }
  
    ]
  );
  }

  OpenSpinner() {
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
    }, 5000);
  }
}
