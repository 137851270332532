<!DOCTYPE html>
<html lang="en">
   <head>
      <!-- basic -->
      <meta charset="utf-8">
      <meta http-equiv="X-UA-Compatible" content="IE=edge">
      <!-- mobile metas -->
      <meta name="viewport" content="width=device-width, initial-scale=1">
      <meta name="viewport" content="initial-scale=1, maximum-scale=1">
      <!-- site metas -->
      <title>Thé tip top - Liste employés</title>
         <meta charset="UTF-8">
         <meta name="viewport" content="width=device-width, initial-scale=1.0">
         <meta name="robots" content="noindex, nofollow">
     
    
   </head>
   <body class="dashboard dashboard_1">
      <div class="full_container">
         <div class="notification error" *ngIf="noResults">{{ errorMessage }}</div>

         <div class="inner_container">
            <nav id="sidebar" >
               <div class="sidebar_blog_1">
                  <div class="sidebar-header">
                
                  </div>
                  <div class="sidebar_user_info">
                     <div class="icon_setting"></div>
                     <div class="user_profle_side">
                        <div class="user_info">
                           <h6>{{user.name}} {{user.lastName}} </h6>
                           <p><span class="online_animation"></span> En ligne</p>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="sidebar_blog_2">
                  <h4>General</h4>
                  <ul class="list-unstyled components">
                     <li class="active">
                        <a href="#dashboard" data-toggle="collapse" aria-expanded="false" routerLink="/admin-dashboard"><i class="fa fa-dashboard yellow_color"></i> <span>Tableau de bord</span></a>
                     
                     </li>
                     <li><a routerLink="/ticket-table"><i class="fa fa-clock-o orange_color"></i> <span>Tickets</span></a></li>
                    
                     <li><a routerLink="/liste-employes"><i class="fa fa-table purple_color2"></i> <span>Employés</span></a></li>
                     <li><a routerLink="/liste-clients"><i class="fa fa-table purple_color2"></i> <span>Clients</span></a></li>
                  </ul>
               </div>
            </nav>
            <div id="content">
               <div class="topbar">
                  <nav class="navbar navbar-expand-lg navbar-light">
                     <div class="full">
                        
                        <button style="background-color: black;" type="button" id="sidebarCollapse" class="sidebar_toggle" (click)="togglesidebar()"><i bold class="bi bi-list" style="font-size: 1.5rem; font-weight: bold;"></i></button>
                        <div class="right_topbar">
                           <div class="icon_info">
                              <ul class="user_profile_dd">
                                 <li>
                                    <span class="logout-pointer" (click)="logout()">Déconnexion</span>
                                 </li>
                              </ul>
                           </div>
                        </div>
                     </div>
                  </nav>
               </div>
               <div class="midde_cont">
                <div class="container-fluid">
                    <div class="row column_title">
                       <div class="col-md-12">
                          <div class="page_title">
                             <h2>Nouveau employé</h2>
                          </div>
                       </div>
                    </div>
                    <div class="row">
                     <div class="container">
                        <h2>Enregistrer un Employé</h2>
                        <form (ngSubmit)="onSubmit()" #employeForm="ngForm" class="form-container">
                          <div class="form-group">
                            <label for="name">Nom:</label>
                            <input id="name" name="name" [(ngModel)]="employeData.name" required class="form-control" placeholder="Entrez le nom" />
                          </div>
                        
                          <div class="form-group">
                            <label for="lastName">Prénom:</label>
                            <input id="lastName" name="lastName" [(ngModel)]="employeData.lastName" required class="form-control" placeholder="Entrez le prénom" />
                          </div>
                        
                          <div class="form-group">
                            <label for="email">Email:</label>
                            <input id="email" name="email" [(ngModel)]="employeData.email" type="email" required class="form-control" placeholder="Entrez l'email" />
                          </div>
                        
                          <div class="form-group">
                            <label for="password">Mot de passe:</label>
                            <input id="password" name="password" [(ngModel)]="employeData.password" type="password" required class="form-control" placeholder="Entrez le mot de passe" />
                          </div>
                        
                          <div class="form-group">
                            <label for="sexe">Sexe:</label>
                            <select id="sexe" name="sexe" [(ngModel)]="employeData.sexe" required class="form-control">
                              <option value="Homme">Homme</option>
                              <option value="Femme">Femme</option>
                            </select>
                          </div>
                        
                          <div class="form-group">
                            <label for="age">Âge:</label>
                            <input id="age" name="age" [(ngModel)]="employeData.age" type="number" required class="form-control" placeholder="Entrez l'âge" />
                          </div>
                        
                          <button type="submit" [disabled]="employeForm.invalid" class="btn btn-primary">Enregistrer</button>
                        </form>
                        
                         
                      </div>
                      
                    </div>
                 </div>
                  <div class="container-fluid">
                     <div class="footer">
                        <p>Copyright © 2024 Conçu par Hedi Ben Khouja. Tous droits réservés.. All rights reserved.</p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </body>
</html>
