import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';



@Injectable({
  providedIn: 'root',
})
export class GameService {

    constructor(private http: HttpClient) {}


    play(userId: any, ticketCode: any){
        return this.http.post(
            `${environment.apiUrl}/api/assign-ticket`,
            {
                userId: userId,
                ticketCode: ticketCode,
            }
          );

    }

    updateRemis(ticketId: string, remis: boolean) {
        return this.http.put(`${environment.apiUrl}/api/tickets/${ticketId}/remis`, { remis });
      }

  
      getTickets(page: number = 1, limit: number = 10): Observable<any> {
        let params = new HttpParams()
          .set('page', page.toString())
          .set('limit', limit.toString());
    
        return this.http.get<any>(`${environment.apiUrl}/api/tickets-user`, { params });
      }


  getUserTickets(userId: string) {
    return this.http.get(`${environment.apiUrl}/api/users/${userId}/tickets`);
  }
  

  getUserById(userId: string) {
    return this.http.get(`${environment.apiUrl}/api/users/${userId}`);
  }

  getTicketsValidated() {
    return this.http.get<{ usedTicketsCount: number }>(
      `${environment.apiUrl}/api/used-tickets-count`
    );
  }

  getRemisTicketsCount() {
    return this.http.get<{ remisTicketsCount: number }>(
      `${environment.apiUrl}/api/remis-tickets-count`
    );
  }
  getAgeCategoryTicketCounts(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/api/tickets-count-by-age`);
  }
  
  getUsedTicketsCountByGender(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/api/used-tickets-count-gender`);
  }
  
 
  getEmployees() {
    return this.http.get<any[]>(`${environment.apiUrl}/api/employees`);
  }

  deleteEmployee(id: string) {
    return this.http.delete(`${environment.apiUrl}/api/employees/${id}`);
  }

  getClients() {
    return this.http.get<any[]>(`${environment.apiUrl}/api/clients`);
  }

  deleteClient(id: string) {
    return this.http.delete(`${environment.apiUrl}/api/clients/${id}`);
  }
  getTicketCountsByValue(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/api/tickets-count-by-value`);
  }

  getLimitedEmployees(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiUrl}/api/employees-limited`);
  }

  getLimitedClients(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiUrl}/api/clients-limited`);
  }

  getLatestTickets(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiUrl}/api/latest-tickets`);
  }
  registerUser(userData: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/api/register`, userData);
  }
  registerEmploye(userData: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/api/register-employe`, userData);
  }


  searchTicketByCode(code: string): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/tickets/${code}`);
  }
  searchEmployeesByName(name: string): Observable<any[]> {
    let params = new HttpParams().set('name', name);
    return this.http.get<any[]>(`${environment.apiUrl}/api/search-employees`, { params });
  }
  searchClientsByName(name: string): Observable<any[]> {
    let params = new HttpParams().set('name', name);
    return this.http.get<any[]>(`${environment.apiUrl}/api/search-clients`, { params });
  }

  sendContactEmail(name: string, email: string, subject: string, message: string): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/api/contact`, {
      name,
      email,
      subject,
      message
    });
  }
  updateUser(userData: any): Observable<any> {
    return this.http.patch<any>(`${environment.apiUrl}/api/users/${userData._id}`, userData);
  }
}