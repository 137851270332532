import { Component, OnInit } from '@angular/core';
import { GameService } from '../services/game.service';
import { AuthService } from '../services/auth.service'; 

@Component({
  selector: 'app-liste-clients',
  templateUrl: './liste-clients.component.html',
  styleUrls: ['./liste-clients.component.css']
})
export class ListeClientsComponent implements OnInit {
  clients: any[] = [];
  paginatedClients: any[] = [];
  page: number = 1;
  totalPages: number = 1;
  itemsPerPage: number = 10;
  user: any;
  userId: string | null = null; 
  searchName: string = '';
  noResults: boolean = false; 
  errorMessage: string = '';

  constructor(private gameService: GameService,    private authService: AuthService 
  ) {}

  ngOnInit(): void {
    this.userId = this.authService.getUserId(); 
    if (this.userId) {
      this.fetchUserByid();
    } else {
      console.error('User ID is not available.');
    }
    this.fetchClients();
  }

  fetchUserByid() {
    if (this.userId) {
      this.gameService.getUserById(this.userId).subscribe(
        (data) => {
          this.user = data;
        },
        (error) => {
          console.error('Error fetching user data', error);
        }
      );
    }
  }
  fetchClients(): void {
    this.gameService.getClients().subscribe(
      (data) => {
        this.clients = data;
        this.totalPages = Math.ceil(this.clients.length / this.itemsPerPage);
        this.loadPage(this.page);
      },
      (error) => {
        console.error('Error fetching clients:', error);
      }
    );
  }

  loadPage(page: number): void {
    this.page = page;
    const start = (this.page - 1) * this.itemsPerPage;
    const end = start + this.itemsPerPage;
    this.paginatedClients = this.clients.slice(start, end);
  }

  changePage(page: number): void {
    if (page < 1 || page > this.totalPages) return;
    this.loadPage(page);
  }

  confirmDelete(id: string): void {
    if (confirm('Êtes-vous sûr de vouloir supprimer ce client?')) {
      this.deleteClient(id);
    }
  }

  deleteClient(id: string): void {
    this.gameService.deleteClient(id).subscribe(
      () => {
        this.clients = this.clients.filter(client => client._id !== id);
        this.totalPages = Math.ceil(this.clients.length / this.itemsPerPage);
        this.loadPage(this.page);
        alert('Client deleted successfully');
      },
      (error) => {
        console.error('Error deleting client:', error);
        alert('Failed to delete client');
      }
    );
  }
  logout(): void {
    this.authService.logout(); 
    window.location.href = '/login'; 
  }


  
  searchClients() {
    this.noResults = false; 
    this.errorMessage = ''; 
    if (this.searchName.trim()) {
      this.gameService.searchClientsByName(this.searchName).subscribe(
        (data) => {
          if (data.length === 0) {
            this.noResults = true; 
            this.errorMessage = 'Aucun employé trouvé correspondant aux critères de recherche.';
            setTimeout(() => this.noResults = false, 3000);

            this.fetchClients(); 
          } else {
            this.clients = data;
            this.totalPages = Math.ceil(this.clients.length / this.itemsPerPage);
            this.loadPage(1); 
          }
        },
        (error) => {
          console.error('Error fetching employees:', error);
          this.errorMessage = 'An error occurred while searching for employees.';
        }
      );
    } else {
      this.fetchClients(); 
    }
  }
  resetSearch() {
    this.searchName = '';
    this.fetchClients(); 
  }
}
