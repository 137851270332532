import { Component, OnInit,HostListener } from '@angular/core';
import { GameService } from '../services/game.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../services/auth.service';  // Import the AuthService

import { Title } from '@angular/platform-browser';
import { Meta } from '@angular/platform-browser';
@Component({
  selector: 'app-jeux-concours',
  templateUrl: './jeux-concours.component.html',
  styleUrls: ['./jeux-concours.component.css']
})
export class JeuxConcoursComponent implements OnInit {
  collapseMenu = false;
  isDesktop = true;

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.isDesktop = window.innerWidth > 768;
  }
  ticketCode: any = '';
  ticket: any;
  userId: any;
  errormessage: any;
  user: any;

  constructor(
    private gameService: GameService,
    private spinner: NgxSpinnerService,
    private authService: AuthService,
    private titleService: Title, private metaService: Meta 
  ) {}

  ngOnInit() {
    this.titleService.setTitle('Jeux Concours - Thé Tip Top');
    this.metaService.updateTag({
      name: 'description',
      content:
        'Participez à nos jeux concours et gagnez des cadeaux exclusifs chez Thé Tip Top. Saisissez votre code de ticket et découvrez ce que vous avez gagné !',
    });
    this.isDesktop = !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    this.isDesktop = window.innerWidth > 768;

    this.userId = this.authService.getUserId(); 
    if (this.userId) {
      this.fetchUserByid();
    } else {
      console.error('User ID is not available.');
    }
    
  }
  toggleMenu() {
    this.collapseMenu = !this.collapseMenu;
  }
  logout(): void {
    this.authService.logout(); 
    window.location.href = '/login'; 
  }
  fetchUserByid() {
    if (this.userId) {
      this.gameService.getUserById(this.userId).subscribe(
        (data) => {
          this.user = data;
        },
        (error) => {
          console.error('Error fetching user data', error);
        }
      );
    }
  }
  play() {
    if (!this.userId) {
      console.error('User ID is not available');
      return;
    }

    this.spinner.show();
    setTimeout(() => {
      this.gameService.play(this.userId, this.ticketCode).subscribe(
        (ticket) => {
          this.spinner.hide();
          this.ticket = ticket;

          const ticketModal = document.getElementById('ticketModalButton');
          if (ticketModal) {
            ticketModal.click();
          }
        },
        (err) => {
          this.spinner.hide();
          console.error('Error playing the game:', err);
          this.errormessage='Le code est déja utilisé';
        }
      );
    }, 5000);
  }
}
