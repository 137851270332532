
    <head>
        <title>Jeux Concours - Thé Tip Top</title>
    <meta name="description" content="Participez à nos jeux concours et gagnez des cadeaux exclusifs chez Thé Tip Top. Saisissez votre code de ticket et découvrez ce que vous avez gagné !">
    
    </head>
<nav class="navbar navbar-expand-lg navbar-dark ftco_navbar bg-transparent ftco-navbar-light" id="ftco-navbar">
    <div class="container d-flex justify-content-between">
        <img src="assets/images/logo.png" class="navbar-logo" alt="Logo">
        <button
  class="navbar-toggler"
  type="button"
  (click)="toggleMenu()"
  [attr.aria-expanded]="collapseMenu"
  aria-controls="ftco-nav"
  aria-label="Toggle navigation"
>
  <span class="oi oi-menu"></span>  <i class="bi bi-list icon-large-bold"></i>
</button>


<div class="collapse navbar-collapse justify-content-end" id="ftco-nav" [ngClass]="{ 'show': collapseMenu }">
    <ul class="navbar-nav">
      <li class="nav-item"><a routerLink="/" class="nav-link">Accueil</a></li>
      <li class="nav-item"><a routerLink="/jeux-concours" class="nav-link">Jeux concours</a></li>
      <li class="nav-item"><a routerLink="/mentions-legales" class="nav-link">Mentions Légales</a></li>
      <li class="nav-item"><a routerLink="/contact" class="nav-link">Contact</a></li>
      <li class="nav-item"><a routerLink="/profile" class="nav-link">Profile</a></li>
      <li class="nav-item"><a routerLink="/login" (click)="logout()" class="nav-link">Déconnexion</a></li>
    </ul>
  </div>
  
    </div>
</nav>

        
    <ngx-spinner bdColor = "rgba(212, 202, 104, 0.99)" size = "medium" color = "#fff"  [fullScreen] = "true"><div class="container">
    <div class="col-md-12">
        <div class="content fullscreen">
            <div class="circle"></div>
            <div class="circle"></div>
            <div class="circle"></div>
            <div class="circle"></div>
        </div>
    </div>
</div>
</ngx-spinner>
<div class="page-not-found-container" style="margin-top: -100px;">
    <img src="assets/images/gamej.gif" alt="Page not found" class="error-image">
    <div class="text-container">
        <div class="content-box">
            <h1 style="color: black;">Gagner n'aura jamais été aussi simple</h1>
            <p >Cliquer sur le bouton "jouer", mentionnez votre numéro de facture et GAGNEZ</p>
            <input type="text" class="form-control" placeholder="Tapper le code de votre ticket" aria-label="ticket-code" [(ngModel)]="ticketCode">
            <a class="home-button"  (click)="play()"><span>Jouer</span></a>
            <div *ngIf="errormessage" class="alert alert-danger">
Le code est déja utilisé              </div>
              
        </div>
    </div>
</div>

<!-- Button trigger modal -->
<button type="button" id="ticketModalButton" class="btn btn-primary" hidden="true" data-bs-toggle="modal" data-bs-target="#ticketModal">
    Launch Ticket Modal
  </button>
  
  <!-- Modal -->
  <div class="modal fade" id="ticketModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">Félicitations !</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <p class="text-center">Vous venez de gagner le cadeau suivant : </p>
            <div class="property-wrap ftco-animate" *ngIf="ticket.value.includes('infuseur')">
                <div class="img d-flex align-items-center justify-content-center" style="background-image: url(assets/images/infuseur.jpg);background-size: contain; background-repeat: no-repeat; background-position: center;">
                    <a routerLink="/profile" data-bs-dismiss="modal" class="icon d-flex align-items-center justify-content-center btn-custom">
                        <i class="bi bi-person"></i>
                    </a>
                </div>
                <div class="text">
                    <p class="price mb-3"><span class="orig-price">Cadeau N°1</span></p>
                    <h3 class="mb-0"><a data-bs-dismiss="modal" routerLink="/profile">Infuseur à thé</a></h3>
                    <span class="location d-inline-block mb-3"><i class="ion-ios-pin mr-2"></i>Un infuseur à thé permet d'infuser le thé en vrac.</span>
                    
                </div>
            </div>
            <div class="property-wrap ftco-animate"  *ngIf="ticket.value.includes('thé détox')">
                <div class="img d-flex align-items-center justify-content-center" style="background-image: url(assets/images/the-box.jpg);background-size: contain; background-repeat: no-repeat; background-position: center;">
                    <a data-bs-dismiss="modal" routerLink="/profile" class="icon d-flex align-items-center justify-content-center btn-custom">
                        <i class="bi bi-person"></i>
                    </a>
                
                </div>
                <div class="text">
                    <p class="price mb-3"><span class="orig-price">Cadeau N°2</span></p>
                    <h3 class="mb-0"><a data-bs-dismiss="modal" routerLink="/profile"> Une boite de thé détox ou d’infusion</a></h3>
                    <span class="location d-inline-block mb-3"><i class="ion-ios-pin mr-2"></i>Une boîte de thé détox pour purifier et revitaliser.</span>
                    
                </div>
            </div>
            <div class="property-wrap ftco-animate"  *ngIf="ticket.value.includes('thé signature')">
                <div class="img d-flex align-items-center justify-content-center" style="background-image: url(assets/images/signature.jpg);background-size: contain; background-repeat: no-repeat; background-position: center;">
                    <a routerLink="/profile" data-bs-dismiss="modal" class="icon d-flex align-items-center justify-content-center btn-custom">
                        <i class="bi bi-person"></i>
                    </a>
                    
                </div>
                <div class="text">
                    <p class="price mb-3"><span class="orig-price">Cadeau N°3</span></p>
                    <h3 class="mb-0"><a data-bs-dismiss="modal" routerLink="/profile">Une boite de de thé signature</a></h3>
                    <span class="location d-inline-block mb-3"><i class="ion-ios-pin mr-2"></i>Une boîte de thé offrant une expérience unique.






                    </span>
                
                </div>
            </div>
            <div class="property-wrap ftco-animate"  *ngIf="ticket.value.includes('coffret découverte d’une valeur de 39€')">
                <div class="img d-flex align-items-center justify-content-center" style="background-image: url(assets/images/coffret1.jpg);background-size: contain; background-repeat: no-repeat; background-position: center;">
                    <a routerLink="/profile" data-bs-dismiss="modal" class="icon d-flex align-items-center justify-content-center btn-custom">
                        <i class="bi bi-person"></i>
                    </a>
                    
                </div>
                <div class="text">
                    <p class="price mb-3"><span class="orig-price">Cadeau N°4</span></p>
                    <h3 class="mb-0"><a data-bs-dismiss="modal" routerLink="/profile">Un coffret découverte d'une valeur de 39 €</a></h3>
                    <span class="location d-inline-block mb-3"><i class="ion-ios-pin mr-2"></i>Un coffret offrant une sélection variée de thés</span>
                    
                </div>
            </div>
            <div class="property-wrap ftco-animate"  *ngIf="ticket.value.includes('coffret découverte d’une valeur de 69€')">
                <div class="img d-flex align-items-center justify-content-center" style="background-image: url(assets/images/coffret2.jpg);background-size: contain; background-repeat: no-repeat; background-position: center;">
                    <a routerLink="/profile"data-bs-dismiss="modal" class="icon d-flex align-items-center justify-content-center btn-custom">
                        <i class="bi bi-person"></i>
                    </a>
                
                </div>
                <div class="text">
                    <p class="price mb-3"><span class="orig-price">Cadeau N°5</span></p>
                    <h3 class="mb-0"><a data-bs-dismiss="modal" routerLink="/profile">Un coffret découverte d'une valeur de 69 €</a></h3>
                    <span class="location d-inline-block mb-3"><i class="ion-ios-pin mr-2"></i>Un coffret offrant une sélection variée de thés</span>
                    
                </div>
            </div>
            <div class="property-wrap ftco-animate"  *ngIf="ticket.value.includes('an de thé')">
                <div class="img d-flex align-items-center justify-content-center" style="background-image: url(assets/images/work-6.jpg);background-size: contain; background-repeat: no-repeat; background-position: center;">
                    <div class="list-agent d-flex align-items-center">
                        <a href="#" class="agent-info d-flex align-items-center">
                            <div class="img-2 rounded-circle" style="background-image: url(assets/images/person_1.jpg);"></div>
                            <h3 class="mb-0 ml-2">Ben Ford</h3>
                        </a>
                        <div class="tooltip-wrap d-flex">
                            <a href="#" class="icon-2 d-flex align-items-center justify-content-center" data-toggle="tooltip" data-placement="top" title="Bookmark">
                                <span class="ion-ios-heart"><i class="sr-only">Bookmark</i></span>
                            </a>
                            <a href="#" class="icon-2 d-flex align-items-center justify-content-center" data-toggle="tooltip" data-placement="top" title="Compare">
                                <span class="ion-ios-eye"><i class="sr-only">Compare</i></span>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="text">
                    <p class="price mb-3"><span class="old-price">800,000</span><span class="orig-price">$3,050<small>/mo</small></span></p>
                    <h3 class="mb-0"><a href="properties-single.html">1 an de thé d'une valeur de 360$</a></h3>
                    <span class="location d-inline-block mb-3"><i class="ion-ios-pin mr-2"></i>2854 Meadow View Drive, Hartford, USA</span>
                    <ul class="property_list">
                        <li><span class="flaticon-bed"></span>3</li>
                        <li><span class="flaticon-bathtub"></span>2</li>
                        <li><span class="flaticon-floor-plan"></span>1,878 sqft</li>
                    </ul>
                </div>
            </div>
            <h6 class="text-center">Votre cadeau vous attends en magasin !</h6>
            <p class="text-center">Profitez-en bien !</p>
        </div>
      </div>
    </div>
  </div><app-footer></app-footer>
