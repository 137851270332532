import { Injectable } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { AuthService } from './services/auth.service';

export const authGuard: CanActivateFn = () => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const user = authService.getUser();
  if (user && user.role === 'client') {
    return true;
  }
  router.navigate(['/login']);
  return false;

  
};
export const adminOrEmployeGuard: CanActivateFn = () => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const user = authService.getUser();

  if (user && (user.role === 'admin' || user.role === 'employe')) {
    return true;
  }

  router.navigate(['/not-found']);
  return false;
};