<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Thé Tip Top - Contactez Nous</title>
  <meta name="description" content="Contactez-nous pour toute question ou information. Nous sommes là pour vous aider avec vos besoins concernant nos boutiques, jeux-concours, et produits.">
</head>
<app-navbar></app-navbar>
<section
  class="hero-wrap  ftco-degree-bg js-fullheight"
  style="background-image: url('assets/images/bg_1.jpg');"
  data-stellar-background-ratio="0.5"
>
  <div class="overlay"></div>
  <div class="overlay-2"></div>
  <div class="container">
    <div class="row no-gutters slider-text justify-content-center align-items-center">
      <div class="col-lg-8 col-md-6 ftco-animate d-flex align-items-end">
          <div class="text text-center w-100">
            <h1 class="mb-4"> Contactez Nous</h1>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="ftco-section contact-section">
  <div class="container">
    <div class="row d-flex mb-5 contact-info justify-content-center">
      <div class="col-md-8">
        <div class="row mb-5">
          <div class="col-md-4 text-center py-4">
            <div
              class="icon mb-3 d-flex align-items-center justify-content-center"
            >
              <span><i class="bi bi-geo-alt"></i></span>
            </div>
            <p>
              <span>Addresse:</span> 6 rue Anatole Forge,
              75017 Paris,France
            </p>
          </div>
          <div class="col-md-4 text-center py-4">
            <div
              class="icon mb-3 d-flex align-items-center justify-content-center"
            >
              <span><i class="bi bi-phone"></i></span>
            </div>
            <p>
              <span>Tel:</span>
              <a href="tel://+33 321 795 955">+33 321 795 955</a>
            </p>
          </div>
          <div class="col-md-4 text-center py-4">
            <div
              class="icon mb-3 d-flex align-items-center justify-content-center"
            >
              <span><i class="bi bi-envelope"></i></span>
            </div>
            <p>
              <span>Email:</span>
              <a href="mailto:thetiptop-jeuxconcours@outlook.com">thetiptop-jeuxconcours@outlook.com</a>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row block-9 justify-content-center mb-5">
      <div class="col-md-6 align-items-stretch d-flex">
        <form (ngSubmit)="sendEmail()" #contactForm="ngForm" class="bg-light p-5 contact-form">
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              placeholder="Your Name"
              name="name"
              [(ngModel)]="contact.name"
              required
            />
          </div>
          <div class="form-group">
            <input
              type="email"
              class="form-control"
              placeholder="Your Email"
              name="email"
              [(ngModel)]="contact.email"
              required
            />
          </div>
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              placeholder="Subject"
              name="subject"
              [(ngModel)]="contact.subject"
              required
            />
          </div>
          <div class="form-group">
            <textarea
              name="message"
              cols="30"
              rows="7"
              class="form-control"
              placeholder="Message"
              [(ngModel)]="contact.message"
              required
            ></textarea>
          </div>
          <div class="form-group">
            <input
              type="submit"
              value="Send Message"
              class="btn btn-primary py-3 px-5"
            />
          </div>
        </form>
      </div>
      <div class="col-md-6 align-items-stretch d-flex">
        <div id="map" class="bg-white border"></div>
    </div>
    </div>
  </div>
</section>
<app-footer></app-footer>
