import { Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GameService } from '../services/game.service';
import { AuthService } from '../services/auth.service';
import { Title } from '@angular/platform-browser';
import { Meta } from '@angular/platform-browser';

import { Router } from '@angular/router';

@Component({
  selector: 'app-inscription',
  templateUrl: './inscription.component.html',
  styleUrls: ['./inscription.component.css']
})
export class InscriptionComponent implements OnInit {
  signUpForm!: FormGroup;
  showSuccessMessage: boolean = false;
  showErrorMessage: boolean = false;
  successMessage: string = '';
  errorMessage: string = '';
  loginForm!: FormGroup;

  constructor(
    private elementRef: ElementRef,
    private fb: FormBuilder,
    private gameService: GameService,
    private authService: AuthService,
    private router: Router,
    private titleService: Title, private metaService: Meta 


  ) {}

  ngOnInit() {
    this.signUpForm = this.fb.group({
      name: ['', [Validators.required, Validators.pattern('^[a-zA-Z]+$')]],
      lastName: ['', [Validators.required, Validators.pattern('^[a-zA-Z]+$')]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      sexe: ['', Validators.required],
      age: ['', [Validators.required, Validators.pattern('^[0-9]+$')]]
    });

    const signUpButton = this.elementRef.nativeElement.querySelector('#signUp');
    const signInButton = this.elementRef.nativeElement.querySelector('#signIn');
    const container = this.elementRef.nativeElement.querySelector('#container');

    signUpButton.addEventListener('click', () => {
      container.classList.add("right-panel-active");
    });

    signInButton.addEventListener('click', () => {
      container.classList.remove("right-panel-active");
    });

    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });
    this.titleService.setTitle('Connexion & Inscription - Thé Tip Top');
  this.metaService.addTags(
  [
    { name: 'description',  content:
      'Connectez-vous ou inscrivez-vous pour participer à nos jeux-concours et profiter des meilleures offres du Thé Tip Top. Toujours gagnant avec nous!',
  },
    { name: 'robots', content: 'index, follow' }

  ]
);
  
  }

  onSubmit() {
    if (this.signUpForm.valid) {
      this.gameService.registerUser(this.signUpForm.value).subscribe(
        (response) => {
          this.successMessage = 'Inscription validée!';
          this.showSuccessMessage = true;
          this.errorMessage = ''; 
          setTimeout(() => this.showSuccessMessage = false, 3000); 
        },
        (error) => {
          console.error('Error registering user', error);
          if (error.status === 400 && error.error.message === 'Email already exists') {
            this.errorMessage = 'Email déjà utilisé.';
          } else {
            this.errorMessage = 'Une erreur est survenue. Veuillez réessayer.';
          }
          this.showErrorMessage = true;
          this.successMessage = ''; 
          setTimeout(() => this.showErrorMessage = false, 3000); 
        }
      );
    } else {
      this.showErrorMessage = true;
      this.successMessage = ''; 
      this.errorMessage = this.getAllErrorMessages();
      setTimeout(() => this.showErrorMessage = false, 3000); 
    }
  }

  getAllErrorMessages(): string {
    const errorMessages: string[] = [];

    if (this.signUpForm.get('name')?.hasError('pattern') && this.signUpForm.get('name')?.touched) {
      errorMessages.push('Nom doit être composé uniquement de lettres.');
    }
    if (this.signUpForm.get('lastName')?.hasError('pattern') && this.signUpForm.get('lastName')?.touched) {
      errorMessages.push('Prénom doit être composé uniquement de lettres.');
    }

    if (this.signUpForm.get('email')?.hasError('email') && this.signUpForm.get('email')?.touched) {
      errorMessages.push('Email invalide.');
    }

    if (this.signUpForm.get('password')?.hasError('minlength') && this.signUpForm.get('password')?.touched) {
      errorMessages.push('Le mot de passe doit contenir au moins 6 caractères.');
    }

    if (this.signUpForm.get('age')?.hasError('pattern') && this.signUpForm.get('age')?.touched) {
      errorMessages.push('L’âge doit contenir uniquement des chiffres.');
    }

    if (this.signUpForm.get('sexe')?.hasError('required') && this.signUpForm.get('sexe')?.touched) {
      errorMessages.push('Le sexe est requis.');
    }

    return errorMessages.length > 0 ? errorMessages.join(' ') : 'Veuillez vérifier les informations saisies.';
  }












  //login

  onLogin(): void {
    if (this.loginForm.invalid) {
      return;
    }

    const { email, password } = this.loginForm.value;
    
    this.authService.login(email, password).subscribe(
      response => {
        this.authService.setToken(response.token);
        this.authService.setUser(response.user);
        this.successMessage = 'Connexion réussie !';
        this.errorMessage = '';

        // Redirect based on user role
        const role = this.authService.getUserRole();
        if (role === 'admin') {
          this.router.navigate(['/admin-dashboard']);
        } else if (role === 'client') {
          this.router.navigate(['/profile']);
        }else if (role === 'employe') {
          this.router.navigate(['/ticket-table']);
        }
      },
      error => {
        this.errorMessage = 'Email ou mot de passe invalide.';
        this.successMessage = '';
      }
    );
  }
}
