import { Component, OnInit } from '@angular/core';
import { GameService } from '../services/game.service';
import { AuthService } from '../services/auth.service'; 

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  userId: string | null = null; 
  userTickets: any[] = [];
  user: any;
  editMode: boolean = false;
  editableUser: any = {};

  constructor(private gameService: GameService, private authService: AuthService) {}

  ngOnInit(): void {
    this.userId = this.authService.getUserId(); 
    if (this.userId) {
      this.fetchUserTickets();
      this.fetchUserByid();
      console.log(this.editableUser); 

    } else {
      console.error('User ID is not available.');
    }
  
       }
  toggleEditMode(): void {
    this.editMode = !this.editMode;
    this.editableUser= this.user;
   
}
saveProfile(): void {
  if (!this.editableUser._id) {
    console.error('User ID is missing');
    return;
  }

  this.gameService.updateUser(this.editableUser).subscribe(
    updatedUser => {
      this.user = updatedUser;
      this.toggleEditMode(); 
    },
    error => {
      console.error('Error updating profile:', error);
    }
  );
}

  fetchUserByid() {
    if (this.userId) {
      this.gameService.getUserById(this.userId).subscribe(
        (data) => {
          this.user = data;
        },
        (error) => {
          console.error('Error fetching user data', error);
        }
      );
    }
  }

  fetchUserTickets() {
    if (this.userId) {
      this.gameService.getUserTickets(this.userId).subscribe(
        (data: any) => {
          this.userTickets = data;
        },
        (error) => {
          console.error('Error fetching user tickets:', error);
        }
      );
    }
  }

  getTicketImage(value: string): string {
    switch (value) {
      case 'coffret découverte d’une valeur de 39€':
        return 'assets/images/coffret1.jpg';
      case 'coffret découverte d’une valeur de 69€':
        return 'assets/images/coffret2.jpg';
      case 'infuseur à thé':
        return 'assets/images/infuseur.jpg';
      case 'boite de 100g d’un thé signature':
        return 'assets/images/signature.jpg';
      case 'boite de 100g d’un thé détox ou d’infusion':
        return 'assets/images/the-box.jpg';
      default:
        return 'https://via.placeholder.com/150?text=Default';
    }
  }
}
