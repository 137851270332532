<!DOCTYPE html>
<html lang="en">
   <head>
      <!-- basic -->
      <meta charset="utf-8">
      <meta http-equiv="X-UA-Compatible" content="IE=edge">
      <!-- mobile metas -->
      <meta name="viewport" content="width=device-width, initial-scale=1">
      <meta name="viewport" content="initial-scale=1, maximum-scale=1">
      <!-- site metas -->
      <title>Thé Tip Top - Admin dashboard</title>
         <meta charset="UTF-8">
         <meta name="robots" content="noindex, nofollow">
      <!-- site icon -->
      
    
   </head>
   <body class="dashboard dashboard_1">
      <div class="full_container">
         <div class="inner_container">
            <nav id="sidebar">
               <div class="sidebar_blog_1">
                  <div class="sidebar-header">
                
                  </div>
                  <div class="sidebar_user_info">
                     <div class="icon_setting"></div>
                     <div class="user_profle_side">
                        <div class="user_info">
                           <h6>{{user.name}} {{user.lastName}} </h6>
                           <p><span class="online_animation"></span> En ligne</p>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="sidebar_blog_2">
                  <h4>General</h4>
                  <ul class="list-unstyled components">
                     <li  class="active" *ngIf="user.role!=='employe'">
                        <a  data-toggle="collapse" aria-expanded="false"  routerLink="/admin-dashboard"><i class="fa fa-dashboard yellow_color"></i> <span>Tableau de bord</span></a>
                     
                     </li>
                     <li><a routerLink="/ticket-table"><i class="fa fa-clock-o orange_color"></i> <span>Tickets</span></a></li>
                    
                     <li *ngIf="user.role!=='employe'"><a routerLink="/liste-employes" ><i class="fa fa-table purple_color2"></i> <span>Employés</span></a></li>
                     <li><a routerLink="/liste-clients"><i class="fa fa-table purple_color2"></i> <span>Clients</span></a></li>

                  
                  </ul>
               </div>
            </nav>
             <div id="content">
               <div class="topbar">
                  <nav class="navbar navbar-expand-lg navbar-light">
                     <div class="full">
                        <button style="background-color: black;" type="button" id="sidebarCollapse" class="sidebar_toggle"><i bold class="bi bi-list" style="font-size: 1.5rem;
            font-weight: bold;"></i></button>
                        <div class="right_topbar">
                           <div class="icon_info">
                             
                              <ul class="user_profile_dd">
                                 <li>
                                    <span class="logout-pointer" (click)="logout()">Déconnexion</span>
                                   
                                 </li>
                              </ul>
                           </div>
                        </div>
                     </div>
                  </nav>
               </div>
                <div class="midde_cont">
                  <div class="container-fluid">
                     <div class="row column_title">
                        <div class="col-md-12">
                           <div class="page_title">
                              <h2>Tableau de board</h2>
                           </div>
                        </div>
                     </div>
                     <div class="row column1">
                        <div class="col-md-6 col-lg-3">
                           <div class="full counter_section margin_bottom_30">
                              <div class="couter_icon">
                                 <div> 
                                  <i class="bi bi-ticket yellow_color"></i>
                                 </div>
                              </div>
                              <div class="counter_no">
                                 <div>
                                    <p class="total_no">{{500000-ticketsValidatedCount}}</p>
                                    <p class="head_couter">Tickets restantes</p>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-md-6 col-lg-3">
                           <div class="full counter_section margin_bottom_30">
                              <div class="couter_icon">
                                 <div> 
                                    <i class="bi bi-ticket-detailed-fill blue1_color"></i>
                                 </div>
                              </div>
                              <div class="counter_no">
                                 <div>
                                    <p class="total_no">{{ticketsValidatedCount}}</p>
                                    <p class="head_couter">Tickets validés</p>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-md-6 col-lg-3">
                           <div class="full counter_section margin_bottom_30">
                              <div class="couter_icon">
                                 <div>                                           
                                     <i class="bi bi-gift-fill green_color"></i>
                                 </div>
                              </div>
                              <div class="counter_no">
                                 <div>
                                    <p class="total_no">{{remisTicketsCount}}</p>
                                    <p class="head_couter">Lots remis</p>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-md-6 col-lg-3">
                           <div class="full counter_section margin_bottom_30">
                              <div class="couter_icon">
                                 <div> 
                                    <i class="bi bi-box-seam red_color"></i>                                 </div>
                              </div>
                              <div class="counter_no">
                                 <div>
                                    <p class="total_no">{{ticketsValidatedCount-remisTicketsCount}}</p>
                                    <p class="head_couter">Lots non remis</p>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div> <div class="row column1">
                        <div class="col-lg-6">
                           <div class="white_shd full margin_bottom_30">
                              <div class="full graph_head">
                                 <div class="heading1 margin_0">
                                    <h2>Diagramme à bandes
                                    </h2>
                                 </div>
                              </div>
                              <div class="map_section padding_infor_info">
                                 <canvas id="genderChart"></canvas>                                               
                              </div>
                           </div>
                        </div>
                        <div class="col-lg-6">
                           <div class="white_shd full margin_bottom_30">
                              <div class="full graph_head">
                                 <div class="heading1 margin_0">
                                    <h2>Graphique en ligne
                                    </h2>
                                 </div>
                              </div>
                              <div class="map_section padding_infor_info">
                                 <canvas id="ageCategoryChart"></canvas>                              </div>
                           </div>
                        </div> 
                       
                     </div>
                       <div class="row column3">
                        <div class="col-md-6">
                           <div class="dark_bg full margin_bottom_30">
                              <div class="full graph_head">
                                 <div class="heading1 margin_0">
                                    <h2>Administrateur</h2>
                                 </div>
                              </div>
                              <div class="full graph_revenue">
                                 <div class="row">
                                    <div class="col-md-12">
                                       <div class="content testimonial">
                                          <div id="testimonial_slider" class="carousel slide" data-ride="carousel">
                                             <div class="carousel-inner">
                                                <div class="item carousel-item active">
                                                   <div class="img-box"><img src="assets/images/person_2.jpg" alt=""></div>
                                                   <p class="testimonial">Monsieur Eric Bourdon gérant de la société Thé Tip Top, une SA au capital de 150 000€, située au 18 rue Léon Frot, 75011 Paris.</p>
                                                   <p class="overview"><b>Eric bourdon</b>Gérant Thé Tip Top</p>
                                                </div>
                                               
                                             </div>
                                             
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-md-6">
                           <div class="white_shd full margin_bottom_30">
                              <div class="full graph_head">
                                 <div class="heading1 margin_0">
                                    <h2>Lots remis</h2>
                                 </div>
                              </div>
                              <div class="full progress_bar_inner">
                                 <div class="row">
                                    <div class="col-md-12">
                                       <div class="progress_bar">
                                          <span class="skill" style="width:73%;">Coffret découverte d’une valeur de 39€ <span class="info_valume">{{ ticketCounts['coffret découverte d’une valeur de 39€'] || 0 }} Lots</span></span>                  
                                          <div class="progress skill-bar ">
                                             <div
                                             class="progress-bar progress-bar-animated progress-bar-striped"
                                             role="progressbar"
                                             [attr.aria-valuenow]="ticketCounts['coffret découverte d’une valeur de 39€'] || 0"
                                             aria-valuemin="0"
                                             aria-valuemax="100"
                                             [style.width.%]="ticketCounts['coffret découverte d’une valeur de 39€'] || 0"
                                           ></div>
                                          </div>
                                          <span class="skill" style="width:73%;">Coffret découverte d’une valeur de 69€ <span class="info_valume">{{ ticketCounts['coffret découverte d’une valeur de 69€'] || 0 }} Lots</span></span>   
                                          <div class="progress skill-bar">
                                             <div
                                             class="progress-bar progress-bar-animated progress-bar-striped"
                                             role="progressbar"
                                             [attr.aria-valuenow]="ticketCounts['coffret découverte d’une valeur de 69€'] || 0"
                                             aria-valuemin="0"
                                             aria-valuemax="100"
                                             [style.width.%]="ticketCounts['coffret découverte d’une valeur de 69€'] || 0"
                                           ></div>
                                          </div>
                                          <span class="skill" style="width:73%;">Boite de 100g d’un thé signature <span class="info_valume">{{ ticketCounts['boite de 100g d’un thé signature'] || 0 }} Lots</span></span>
                                          <div class="progress skill-bar">
                                             <div
                                             class="progress-bar progress-bar-animated progress-bar-striped"
                                             role="progressbar"
                                             [attr.aria-valuenow]="ticketCounts['boite de 100g d’un thé signature'] || 0"
                                             aria-valuemin="0"
                                             aria-valuemax="100"
                                             [style.width.%]="ticketCounts['boite de 100g d’un thé signature'] || 0"
                                           ></div>
                                          </div>
                                          <span class="skill" style="width:73%;">Boite de 100g d’un thé détox ou d’infusion <span class="info_valume">{{ ticketCounts['boite de 100g d’un thé détox ou d’infusion'] || 0 }} Lots</span></span>
                                          <div class="progress skill-bar">
                                             <div
                                             class="progress-bar progress-bar-animated progress-bar-striped"
                                             role="progressbar"
                                             [attr.aria-valuenow]="ticketCounts['boite de 100g d’un thé détox ou d’infusion'] || 0"
                                             aria-valuemin="0"
                                             aria-valuemax="100"
                                             [style.width.%]="ticketCounts['boite de 100g d’un thé détox ou d’infusion'] || 0"
                                           ></div>
                                          </div>
                                          <span class="skill" style="width:73%;">Infuseur à thé <span class="info_valume">{{ ticketCounts['infuseur à thé'] || 0 }} Lots</span></span>
                                          <div class="progress skill-bar">
                                             <div
                                             class="progress-bar progress-bar-animated progress-bar-striped"
                                             role="progressbar"
                                             [attr.aria-valuenow]="ticketCounts['infuseur à thé'] || 0"
                                             aria-valuemin="0"
                                             aria-valuemax="100"
                                             [style.width.%]="ticketCounts['infuseur à thé'] || 0"
                                           ></div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="row column4 graph">
                        <div class="col-md-6">
                           <div class="dash_blog">
                              <div class="dash_blog_inner">
                                 <div class="dash_head">
                                    <h3><span><i class="fa fa-calendar"></i>Tikets</span></h3>
                                 </div>
                                 <div class="list_cont">
                                    <p>Derniers tickets validés</p>
                                 </div>
                                 <div class="task_list_main">
                                    <ul class="task_list">
                                       <li *ngFor="let ticket of latestTickets"><a href="#">{{ticket.value}}</a><br><a href="#">Code : {{ticket.code}}</a><br><strong>{{ ticket.useDate | date:'yyyy-MM-dd HH:mm a' }}</strong></li>

                                       
                                    </ul>
                                 </div>
                                 <div class="read_more">
                                    <div class="center"><a class="main_bt read_bt" routerLink="/ticket-table">Voir plus</a></div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-md-6">
                           <div class="dash_blog">
                              <div class="dash_blog_inner">
                                 <div class="dash_head">
                                    <h3><span><i class="fa fa-comments-o"></i> Clients</span></h3>
                                 </div>
                                 <div class="list_cont">
                                    <p>Clients</p>
                                 </div>
                                 <div class="msg_list_main">
                                    <ul class="msg_list">
                                       <li *ngFor="let client of clients">
                                          <span><img *ngIf="client.sex === 'femme'" src="assets/images/useravatar2.png" class="img-responsive" alt="Femme Avatar" />
                                             <img *ngIf="client.sex !== 'femme'" src="assets/images/useravatar.png" class="img-responsive" alt="Homme Avatar" />
                                         </span>
                                          <span>
                                          <span class="name_user">{{ client.name }}{{ client.lastName }}</span>
                                          <span class="msg_user"> {{ client.email }}</span>
                                          <span class="time_ago"> Age : {{ client.age }}</span>
                                          </span>
                                        </li>
                                      
                                    </ul>
                                 </div>
                                 <div class="read_more">
                                    <div class="center"><a class="main_bt read_bt" routerLink="/liste-clients">Voir plus</a></div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="container-fluid">
                     <div class="footer">
                        <p>Copyright © 2024 Conçu par Hedi Ben Khouja. Tous droits réservés.. All rights reserved.</p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
    
   </body>
</html>