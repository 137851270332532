import { adminGuard } from './admin.guard';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccueilComponent } from './accueil/accueil.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { ContactComponent } from './contact/contact.component';
import { CguComponent } from './cgu/cgu.component';
import { InscriptionComponent } from './inscription/inscription.component';
import { JeuxConcoursComponent } from './jeux-concours/jeux-concours.component';
import { LoadingComponent } from './layouts/loading/loading.component';
import { ListeClientsComponent } from './liste-clients/liste-clients.component';
import { ListeEmployesComponent } from './liste-employes/liste-employes.component';
import { MentionsLegalesComponent } from './mentions-legales/mentions-legales.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ProfileComponent } from './profile/profile.component';
import { TicketTableComponent } from './ticket-table/ticket-table.component';
import { authGuard,adminOrEmployeGuard  } from './auth.guard';
import { ClientGuard } from './client.guard';
import { AddEmployeComponent } from './add-employe/add-employe.component';

const routes: Routes = [
  { path: 'contact', component: ContactComponent },
  { path: 'cgu', component: CguComponent },
  { path: 'login', component: InscriptionComponent },
  { path: 'mentions-legales', component: MentionsLegalesComponent },
  { path: 'loading', component: LoadingComponent },
  { path: '', component: AccueilComponent },
  { path: 'jeux-concours', component: JeuxConcoursComponent, canActivate: [authGuard] },
  { path: 'profile', component: ProfileComponent, canActivate: [ClientGuard] },
  { path: 'admin-dashboard', component: AdminDashboardComponent, canActivate: [adminGuard] },
  { path: 'ticket-table', component: TicketTableComponent, canActivate: [adminOrEmployeGuard] },
  { path: 'liste-employes', component: ListeEmployesComponent, canActivate: [adminGuard] },
  { path: 'liste-clients', component: ListeClientsComponent,canActivate: [adminOrEmployeGuard]  },
  { path: 'add-employe', component: AddEmployeComponent,canActivate: [adminGuard] },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
