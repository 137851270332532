<head>
	<title>Connexion & Inscription - Thé Tip Top</title>
<meta name="description" content="Connectez-vous ou inscrivez-vous pour participer à nos jeux-concours et profiter des meilleures offres du Thé Tip Top. Toujours gagnant avec nous!">

</head>
<app-navbar></app-navbar>
<h1 class="title">Toujours gagnant avec nous</h1>
<div class="background">
	<div class="notification success" *ngIf="showSuccessMessage">{{ successMessage }}</div>
	<div class="notification error" *ngIf="showErrorMessage">{{ errorMessage }}</div>
<br>
<div class="container" id="container">
	<div class="form-container sign-up-container">
		<form [formGroup]="signUpForm" (ngSubmit)="onSubmit()">
			<h1>Créer un compte</h1>
			<div class="social-container">
				<a href="#" class="social"><i class="bi bi-facebook"></i></a>
				<a href="#" class="social"><i class="bi bi-google"></i></a>
			</div>
			<span>ou utilisez votre email pour vous inscrire</span>
			<input formControlName="name" type="text" placeholder="Nom" />
			<input formControlName="lastName" type="text" placeholder="Prénom" />
			<input formControlName="email" type="email" placeholder="Email" />
			<input formControlName="password" type="password" placeholder="Mot de passe" />
					<input formControlName="age" type="number" placeholder="Âge" />
					<div>
						<label>Sexe</label>
						<div>
						  <label>
							<input type="radio" formControlName="sexe" value="homme" /> Homme
						  </label>
						  <label>
							<input type="radio" formControlName="sexe" value="femme" /> Femme
						  </label>
						</div>
					  </div>	
			<button type="submit">Inscription</button>
		</form>
		  
	</div>
	<div class="form-container sign-in-container">
		<form [formGroup]="loginForm" (ngSubmit)="onLogin()">
			<h1>Connexion</h1>
			<div class="social-container">
				<a href="#" class="social"><i class="bi bi-facebook"></i></a>
				<a href="#" class="social"><i class="bi bi-google"></i></a>		</div>
			<span>ou utilisez votre compte</span>
			<input formControlName="email" type="email" placeholder="Email" />
			<input formControlName="password" type="password" placeholder="Mot de passe" />
			<a href="#">Mot de passe oublié ?</a>
			<button type="submit">Connexion</button>
			<div *ngIf="successMessage" class="success-message">{{ successMessage }}</div>
			<div *ngIf="errorMessage" class="error-message">{{ errorMessage }}</div>
		  </form>
	</div>
	<div class="overlay-container">
		<div class="overlay">
			<div class="overlay-panel overlay-left">
				<h1>Bienvenue de retour !
                </h1>
				<p>Pour rester connecté avec nous, veuillez vous connecter avec vos informations personnelles
                </p>
				<button class="ghost" id="signIn">Connexion</button>
			</div>
			<div class="overlay-panel overlay-right">
				<h1>Salut, TipToper!                </h1>
				<p>Entrez vos détails personnels et gagner avec nous
                </p>
				<button class="ghost" id="signUp">Inscription</button>
			</div>
		</div>
	</div>
</div>
</div>
<app-footer></app-footer>
