import { Component,OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

import { GameService } from '../services/game.service';
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  contact = {
    name: '',
    email: '',
    subject: '',
    message: ''
  };

  constructor(private gameService: GameService,private titleService: Title, private metaService: Meta) {}
  ngOnInit(): void {
    this.titleService.setTitle('Thé Tip Top - Contactez Nous');
    this.metaService.addTags([
      { name: 'description', content: 'Contactez-nous pour toute question ou information. Nous sommes là pour vous aider avec vos besoins concernant nos boutiques, jeux-concours, et produits.' },
      { name: 'robots', content: 'index, follow' }

    ]);
  }

  sendEmail() {
    if (this.contact.name && this.contact.email && this.contact.subject && this.contact.message) {
      this.gameService.sendContactEmail(
        this.contact.name,
        this.contact.email,
        this.contact.subject,
        this.contact.message
      ).subscribe(
        response => {
          console.log('Email sent successfully', response);
        },
        error => {
          console.error('Error sending email', error);
        }
      );
    } else {
      console.error('All fields are required');
    }
  }
}
