import { Component, OnInit } from '@angular/core';
import { GameService } from '../services/game.service';
import { AuthService } from '../services/auth.service';
import {  Router } from '@angular/router';
import { inject } from '@angular/core';

@Component({
  selector: 'app-add-employe',
  templateUrl: './add-employe.component.html',
  styleUrls: ['./add-employe.component.css']
})

export class AddEmployeComponent implements OnInit{
  employees: any[] = [];
  paginatedEmployees: any[] = [];
  page: number = 1;
  totalPages: number = 1;
  itemsPerPage: number = 10;
  collapse = true;
  searchName: string = '';
  noResults: boolean = false; 
  errorMessage: string = '';
  user: any;
  userId: string | null = null;
  employeData = {
    name: '',
    lastName: '',
    email: '',
    password: '',
    sexe: 'Homme',
    age: null
  };

  constructor(private gameService: GameService, private authService: AuthService) {}

  ngOnInit(): void {
    this.userId = this.authService.getUserId();
    if (this.userId) {
      this.fetchUserByid();
    } else {
      console.error('User ID is not available.');
    }
  }  onSubmit() {
    console.log('Form Data:', this.employeData);
  
    if (!this.employeData.name || !this.employeData.lastName || !this.employeData.email || !this.employeData.password || this.employeData.age === null) {
      alert('Please fill in all required fields.');
      return;
    }
  
    this.gameService.registerEmploye(this.employeData).subscribe(
      
      response => {
        alert('L\'inscription de l\'employé est validé!');
        this.employeData = {
          name: '',
          lastName: '',
          email: '',
          password: '',
          sexe: 'Homme',
          age: null
        };
      },
      error => {
        console.error('Error registering employee:', error);
      }
    );
  }

  isFormValid() {
    const { name, lastName, email, password, sexe, age } = this.employeData;
    const namePattern = /^[a-zA-ZÀ-ÿ\s]+$/;
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return (
      namePattern.test(name) &&
      namePattern.test(lastName) &&
      emailPattern.test(email) &&
      password.length > 0 &&
      sexe &&
      age !== null
    );
  }
  fetchUserByid() {
    if (this.userId) {
      this.gameService.getUserById(this.userId).subscribe(
        (data) => {
          this.user = data;
        },
        (error) => {
          console.error('Error fetching user data', error);
        }
      );
    }
  }

  togglesidebar() {
    this.collapse = !this.collapse;
  }

 
 


  

  logout(): void {
    this.authService.logout();
    window.location.href = '/login';
  }
}


