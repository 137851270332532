import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { ContactComponent } from './contact/contact.component';
import { AccueilComponent } from './accueil/accueil.component';
import { MentionsLegalesComponent } from './mentions-legales/mentions-legales.component';
import { CguComponent } from './cgu/cgu.component';
import { InscriptionComponent } from './inscription/inscription.component';
import { LoadingComponent } from './layouts/loading/loading.component';
import { NgxSpinnerService } from "ngx-spinner";
import { NgxSpinnerModule } from 'ngx-spinner';
import { ReactiveFormsModule } from '@angular/forms';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {  CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NotFoundComponent } from './not-found/not-found.component';
import { JeuxConcoursComponent } from './jeux-concours/jeux-concours.component';
import { ProfileComponent } from './profile/profile.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { TicketTableComponent } from './ticket-table/ticket-table.component';
import { ListeEmployesComponent } from './liste-employes/liste-employes.component';
import { ListeClientsComponent } from './liste-clients/liste-clients.component';
import { AddEmployeComponent } from './add-employe/add-employe.component';
@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    ContactComponent,
    AccueilComponent,
    MentionsLegalesComponent,
    CguComponent,
    InscriptionComponent,
    LoadingComponent,
    NotFoundComponent,
    JeuxConcoursComponent,
    ProfileComponent,
    AdminDashboardComponent,
    TicketTableComponent,
    ListeEmployesComponent,
    ListeClientsComponent,
    AddEmployeComponent  
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,    ReactiveFormsModule

  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [],
  bootstrap: [AppComponent],

})
export class AppModule { }